import { usePage } from "@tecnobit-srl/router";
import { useMemo } from "react";
const useQueryParams = () => {
  const page = usePage();
  return useMemo(() => {
    const baseUrlWithParams = page.ziggy.url + page.route.fullUrl;
    try {
      const parsedUrlWithParams = new URL(baseUrlWithParams);
      const params = new URLSearchParams(parsedUrlWithParams.search);
      return Object.fromEntries(params);
    } catch {
      return {};
    }
  }, [page.route.fullUrl, page.ziggy.url]);
};
export {
  useQueryParams
};
